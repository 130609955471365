import React, { useState, useEffect, useRef } from "react";
import {
  adminGetIbCodes,
  // adminGetNextIbCode,
  adminAddIbCode,
  adminUpdateIbCode,
  adminGenerateCodes,
  adminGetGeneratedCodes,
} from "../../../redux/actions/adminActions";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import {
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Modal,
  Form,
} from "react-bootstrap";
import moment from "moment";
import { CLIENT_ADMIN_URL, WEBSITE_URL } from "../../../config/config";
import { NotificationManager } from "react-notifications";
import Select from "react-select";

const IbCodes = () => {
  const [showAddIbModal, setShowAddIbModal] = useState(false);
  const [showGenerateCodesModal, setShowGenerateCodesModal] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ibCodes, setIbCodes] = useState(null);
  const [counters, setCounters] = useState(null);
  // const [nextIbCode, setNextIbCode] = useState(null);

  const introducer = useRef(null);
  const numOfCodes = useRef(null);

  useEffect(() => {
    triggerLoadIbCodes();
  }, []);

  const triggerLoadIbCodes = () => {
    adminGetIbCodes()
      .then(res => {
        setIbCodes(res.data.ibs);
        setCounters(res.data.counters);
        setLoading(false);
      })
      .catch(err => console.error(err));
  };

  const handleAddIbCode = () => {
    const ibData = {
      newCode: true,
      name: document.getElementById("name").value.trim(),
      agentCode: parseInt(document.getElementById("agentCode").value.trim()),
      statusCode: document.getElementById("statusCode").value.trim(),
    };

    if (
      ibData.name === "" ||
      ibData.agentCode === "" ||
      ibData.statusCode === ""
    ) {
      return false;
    }

    setBtnDisabled(true);
    adminAddIbCode(ibData)
      .then(res => {
        if (res.data.success) {
          if (res.data.addIbCode) {
            createNotification("successAdd");
            setBtnDisabled(false);
            setShowAddIbModal(false);
            setLoading(true);

            triggerLoadIbCodes();
          } else createNotification("error");
        } else {
          createNotification("error");
        }
      })
      .catch(err => console.error(err));
  };

  const handleUpdateIbCode = ibData => {
    adminUpdateIbCode(ibData)
      .then(res => {
        if (res.data.success) {
          if (res.data.addIbCode) {
            createNotification("successAdd");
            setBtnDisabled(false);
            setShowAddIbModal(false);
            setLoading(true);

            triggerLoadIbCodes();
          } else createNotification("successEdit");
        } else {
          createNotification("error");
        }
      })
      .catch(err => console.error(err));
  };

  const handleGenerateCodes = () => {
    if (!introducer.current.select.props.value) return;

    adminGenerateCodes({
      agentCode: introducer.current.select.props.value.value,
      numOfCodes: numOfCodes.current.value,
    })
      .then(res => {
        if (res.data.success) {
          createNotification("successGenerate");
          setBtnDisabled(false);
          setShowGenerateCodesModal(false);
          setLoading(true);

          triggerLoadIbCodes();
        } else {
          createNotification("error");
        }
      })
      .catch(err => console.error(err));
  };

  const handleModalShow = target => {
    setShowAddIbModal(true);
    // if (target === "showAddIbCodeModal") {
    //   adminGetNextIbCode()
    //     .then(res => {
    //       setNextIbCode(res.data);
    //
    //       if (res.data.ibCode) setShowAddIbModal(true);
    //       else createNotification("warningNoIbSlots");
    //     })
    //     .catch(err => console.error(err));
    // }
    if (target === "showGenerateCodesModal") {
      setShowGenerateCodesModal(true);
    }
  };

  const handleModalClose = () => {
    setShowAddIbModal(false);
    setShowGenerateCodesModal(false);
  };

  const columns = [
    {
      dataField: "name",
      text: "NAME",
      sort: true,
      classes: "breakWord",
      editable: true,
    },
    {
      dataField: "agentCode",
      text: "AGENT CODE",
      sort: true,
      editable: false,
    },
    {
      dataField: "statusCode",
      text: "ACC. STATUS",
      sort: false,
      classes: "breakWord",
      editable: false,
    },
    {
      dataField: "link",
      text: "LINK",
      sort: true,
      classes: "breakWord",
      editable: false,
    },
    {
      dataField: "timeCreated",
      text: "ADDED",
      sort: true,
      editable: false,
    },
  ];

  const expandRow = {
    renderer: row => <GeneratedCodes agentCode={row.agentCode} />,
  };

  const defaultSorted = [
    {
      dataField: "timeApproved",
      order: "asc",
    },
  ];

  let data = [];
  if (!!ibCodes) {
    ibCodes.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      ibCodes[keyIndex].timeCreated = moment(ibCodes[keyIndex].timestamp * 1000)
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();

      ibCodes[keyIndex].link =
        `${CLIENT_ADMIN_URL}/register/${ibCodes[keyIndex].agentCode}`;

      data.push(ibCodes[keyIndex]);
    });
  }

  const createNotification = type => {
    switch (type) {
      case "successAdd":
        NotificationManager.success(
          "You have successfully added the Introducer.",
          "IB Code Insert",
          5000
        );
        break;
      case "successGenerate":
        NotificationManager.success(
          "You have successfully generated the Introducer codes.",
          "IB Code Generator",
          5000
        );
        break;
      case "successEdit":
        NotificationManager.success(
          "You have successfully updated the Introducer.",
          "IB Code Update",
          5000
        );
        break;
      case "warningNoIbSlots":
        NotificationManager.warning(
          "You don't have any Introducer slots left. Please add more codes.",
          "IB Code Insert Failed",
          5000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong!", "Error", 5000);
        break;
      default:
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="pt-3 px-3">
              IB Codes {loading ? <Spinner animation="border" /> : null}
              <Button
                type="button"
                variant="secondary"
                className="darkBlueButton float-right ml-1"
                style={{ width: "auto", fontWeight: "normal", marginTop: 0 }}
                onClick={() => handleModalShow("showGenerateCodesModal")}
              >
                Generate codes
              </Button>
              <Button
                type="button"
                variant="secondary"
                className="darkBlueButton float-right"
                style={{ width: "auto", fontWeight: "normal", marginTop: 0 }}
                onClick={() => handleModalShow("showAddIbCodeModal")}
              >
                Add Introducer
              </Button>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            {counters ? (
              <div className="px-4">
                <small>
                  {`Codes left: ${counters.availableIbCodes}/${counters.totalIbCodes}`}
                  <br />
                  {`Subcodes left: ${counters.availableSubcodes}/${counters.totalSubcodes}`}
                </small>
              </div>
            ) : null}

            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              expandRow={expandRow}
              defaultSorted={defaultSorted}
              hover={true}
              headerClasses="tableHeaderClass"
              cellEdit={cellEditFactory({
                mode: "dbclick",
                blurToSave: true,
                afterSaveCell: (oldValue, newValue, row, column) => {
                  if (oldValue.toString() !== newValue.toString()) {
                    handleUpdateIbCode({
                      newCode: false,
                      ibId: row._id,
                      [column.dataField]: row[column.dataField],
                    });
                  }
                },
              })}
            />
          </Col>
        </Row>
      </Container>

      <Modal show={showAddIbModal} onHide={handleModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Introducer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Introducer Name</Form.Label>
            <Form.Control type="text" className="roundedBox" required />
          </Form.Group>

          <Form.Group controlId="agentCode">
            <Form.Label>Agent Code</Form.Label>
            <Form.Control type="text" className="roundedBox" />
          </Form.Group>

          <Form.Group controlId="statusCode">
            <Form.Label>Status Code</Form.Label>
            <Form.Control type="text" className="roundedBox" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-between">
          <Button
            variant="secondary"
            className="greyButton"
            onClick={() => handleModalClose()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="darkBlueButton"
            style={{
              fontWeight: "normal",
            }}
            disabled={btnDisabled}
            onClick={handleAddIbCode}
          >
            {btnDisabled ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Submit
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showGenerateCodesModal} onHide={handleModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Generate Codes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Introducer Name</Form.Label>
            {ibCodes ? (
              <Select
                name="name"
                ref={introducer}
                options={ibCodes.map(ib => ({
                  label: ib.name,
                  value: ib.agentCode,
                }))}
              />
            ) : null}
          </Form.Group>

          <Form.Group>
            <Form.Label>Number of Codes</Form.Label>
            <Form.Control as="select" name="role" required ref={numOfCodes}>
              <option value="1">1 link</option>
              <option value="2">2 links</option>
              <option value="3">3 links</option>
              <option value="10">10 links</option>
              <option value="25">25 links</option>
              <option value="50">50 links</option>
              <option value="100">100 links</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-between">
          <Button
            variant="secondary"
            className="greyButton"
            onClick={() => handleModalClose()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="darkBlueButton"
            style={{
              fontWeight: "normal",
            }}
            disabled={btnDisabled}
            onClick={handleGenerateCodes}
          >
            {btnDisabled ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Submit
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const GeneratedCodes = props => {
  const [generatedCodes, setGeneratedCodes] = useState([]);

  useEffect(() => {
    adminGetGeneratedCodes(props.agentCode)
      .then(res => {
        setGeneratedCodes(res.data.codes);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <Container fluid>
      {generatedCodes.length > 0 ? (
        <>
          <Row className="border-bottom">
            <Col>Name</Col>
            <Col>Link</Col>
          </Row>

          {generatedCodes.map((code, idx) => (
            <Row key={idx} className="border-top py-1">
              <Col>{code.name}</Col>
              <Col>
                {WEBSITE_URL}/?access={code.code}
              </Col>
            </Row>
          ))}
        </>
      ) : (
        <Row>
          <Col>No links generated</Col>
        </Row>
      )}
    </Container>
  );
};

export default IbCodes;
