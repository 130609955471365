import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { countryList, countryDialCodes } from "../../../../config/constants";
import classes from "./Profile.module.css";
import IconPending from "../../../../assets/images/icon-pending.svg";
import IconRejected from "../../../../assets/images/icon-rejected.svg";
import IconApproved from "../../../../assets/images/icon-approved.svg";
import IconNewClient from "../../../../assets/images/icon-new-client.svg";
import moment from "moment";
import ChangeUserAccountType from "../../../../components/Modals/ChangeUserAccountType/ChangeUserAccountType";
import { Button } from "react-bootstrap";

const ProfileBar = props => {
  const [showModal, changeShowModal] = useState(false);
  let userStatusClass;
  let userStatusIcon;

  const toggleModal = () => {
    changeShowModal(!showModal);
  };

  let statusText = props.userData.status;
  switch (props.userData.status) {
    case "new":
      userStatusClass = "statusFlagNewClient text-dark";
      userStatusIcon = IconNewClient;
      statusText = "New Client";
      break;
    case "pending":
      userStatusClass = "statusFlagPending text-dark";
      userStatusIcon = IconPending;
      break;
    case "rejected":
      userStatusClass = "statusFlagRejected";
      userStatusIcon = IconRejected;
      break;
    default:
      userStatusClass = "statusFlagApprovedBigger";
      userStatusIcon = IconApproved;
  }

  return (
    <>
      <ul
        className={[
          "d-flex justify-content-between list-unstyled",
          classes.dashboardBar,
        ].join(" ")}
      >
        <li>
          <span
            className={["statusFlag statusFlagBigger", userStatusClass].join(
              " "
            )}
          >
            <img src={userStatusIcon} alt="" /> {statusText}
          </span>
        </li>
        {props.userData.role === "PARTNER" ? (
          <li>
            <span
              className={["statusFlag statusFlagBigger", userStatusClass].join(
                " "
              )}
              style={{ width: "52px" }}
            >
              <img src={userStatusIcon} alt="" /> IB
            </span>
          </li>
        ) : null}
        <li>
          <small className={classes.dashboardBarCaption}>CLIENT NAME</small>
          {props.userData.name} {props.userData.surname}
        </li>
        <li>
          <small className={classes.dashboardBarCaption}>
            APPLICATION TYPE
          </small>
          <Button
            variant="link"
            className="border-0 p-0 text-success"
            onClick={toggleModal}
          >
            {props.userData.accountType}
          </Button>
        </li>
        <li>
          <small className={classes.dashboardBarCaption}>
            TIME OF REGISTRATION
          </small>
          {moment(props.userData.timestamp * 1000).format(
            "YYYY/MM/DD HH:mm:ss"
          )}
        </li>
        <li>
          <small className={classes.dashboardBarCaption}>
            COUNTRY OF RESIDENCE
          </small>
          {countryList[props.userData.country]}
        </li>
        <li>
          <small className={classes.dashboardBarCaption}>NATIONALITY</small>
          {countryList[props.userData.nationality]}
        </li>
        <li>
          <small className={classes.dashboardBarCaption}>AGENT CODE</small>
          {props.userData.agentCode}
        </li>
        <li>
          <small className={classes.dashboardBarCaption}>EMAIL</small>
          {props.userData.email}
        </li>
        <li>
          <small className={classes.dashboardBarCaption}>PHONE NUMBER</small>(
          {countryDialCodes[props.userData.country]}){" "}
          {props.userData.mobilePhone}
        </li>
      </ul>
      {showModal ? (
        <ChangeUserAccountType
          userData={props.userData}
          toggleModal={toggleModal}
        />
      ) : null}
    </>
  );
};

ProfileBar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(ProfileBar);
