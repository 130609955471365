import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { HUBSPOT_LIFECYCLES } from "../../../config/config";
import { countryList } from "../../../config/constants";
import {
  processIbRequest,
  adminGetIbRequests,
} from "../../../redux/actions/adminActions";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";
import moment from "moment";
import IconApproved from "../../../assets/images/icon-approved.svg";
import IconPending from "../../../assets/images/icon-pending.svg";
import IconPendingYellow from "../../../assets/images/icon-pending-yellow.svg";
import IconRejected from "../../../assets/images/icon-rejected.svg";
import IconNewClient from "../../../assets/images/icon-new-client.svg";
import { FaCrown } from "react-icons/fa";
import { NotificationManager } from "react-notifications";

const IbRequests = props => {
  const inputComment = useRef("");
  const [state, setState] = useState({
    users: [],
    answers: [],
    selectedUser: {},
    loadingRequests: true,
    disableBtn: false,
    editingUser: false,
    errors: {},
  });

  useEffect(() => {
    triggerLoadRequests();

    const userLoader = setInterval(triggerLoadRequests, 300000);

    // Clean up setInterval
    return () => {
      clearInterval(userLoader);
    };
  }, []);

  const triggerLoadRequests = () => {
    setState(prevState => {
      return {
        ...prevState,
        users: [],
        answers: [],
        loadingRequests: true,
      };
    });
    adminGetIbRequests()
      .then(res => {
        setState(prevState => {
          return {
            ...prevState,
            answers: res.data.requestsData,
            users: res.data.usersData,
            loadingRequests: false,
          };
        });
      })
      .catch(err => console.log(err));
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        editingUser: false,
        disableBtn: false,
      };
    });
  };

  const handleProcess = status => {
    setState(prevState => {
      return {
        ...prevState,
        disableBtn: true,
      };
    });
    processIbRequest({
      userId: state.selectedUser._id,
      comment: inputComment.current.value,
      status,
    })
      .then(res => {
        triggerLoadRequests();
        handleCloseModal();
        if (res.data.success) {
          createNotification("success");
        } else createNotification("error", res.data.message);

        setState(prevState => {
          return {
            ...prevState,
            disableBtn: false,
          };
        });
      })
      .catch(err => console.error(err));
  };

  const createNotification = (type, message) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the client.",
          "Client Update",
          5000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  const { users, loadingRequests, selectedUser, disableBtn } = state;

  const selectOptionsCompletedApp = {
    0: "All",
    1: "Incomplete",
    2: "Completed",
  };

  const selectOptionsKYCApproved = {
    0: "All",
    1: "Non-approved",
    2: "Approved",
    3: "Pending",
  };

  // const selectOptionsHubspotLifecycles = HUBSPOT_LIFECYCLES.full;
  const selectOptionsHubspotLifecycles = Object.entries(
    HUBSPOT_LIFECYCLES.full
  ).map(([value, label]) => ({ value, label }));

  const customFilterAgentCode = (filterVal, data) => {
    const search = filterVal.trim();

    if (search === "*") {
      return data.filter(user => user.role === "PARTNER");
    }
    if (search.length > 1) {
      return data.filter(user =>
        user.agentCode ? user.agentCode.toString() === search : false
      );
    }
    return data;
  };

  const customFilterCompletedApp = (filterVal, data) => {
    const search = parseInt(filterVal);

    if (search > 0) {
      return data.filter(
        user =>
          (user.registered === true && search === 2) ||
          (user.registered === false && search === 1)
      );
    }
    return data;
  };

  const customFilterKYCApproved = (filterVal, data) => {
    let search = parseInt(filterVal);

    if (search > 0) {
      // 1: "Non-approved",
      // 2: "Approved",
      // 3: "Pending",
      if (search === 1) return data.filter(user => user.eKYCApproved === false);
      if (search === 2) return data.filter(user => user.eKYCApproved === true);
      if (search === 3) return data.filter(user => user.eKYCPending === true);
      return data;
    }
    return data;
  };

  const customFilterHubspotLifecycles = (filterVal, data) => {
    const search = parseInt(filterVal);

    if (search > 0) {
      return data.filter(user => user.hubspotLifecycleId === search);
    }
    return data;
  };

  const columns = [
    {
      dataField: "userStatus",
      text: "ACCOUNT STATUS",
      // sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "requestStatus",
      text: "IB STATUS",
      // sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "timestampText",
      text: "TIME OF REGISTRATION",
      sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "accountType",
      text: "APPLICATION TYPE",
      // sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "name",
      text: "FIRST NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "surname",
      text: "LAST NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "userCountry",
      text: "COUNTRY OF RESID.",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "agentCodeField",
      text: "AGENT CODE",
      sort: true,
      filter: textFilter({
        placeholder: "Search...",
        onFilter: customFilterAgentCode,
      }),
    },
    {
      dataField: "completedApplication",
      text: "COMPLETE APPLICATION",
      // sort: true,
      filter: selectFilter({
        options: selectOptionsCompletedApp,
        onFilter: customFilterCompletedApp,
      }),
    },
    {
      dataField: "eKYCApprovedIcon",
      text: "eKYC APPROVED",
      // sort: true,
      filter: selectFilter({
        options: selectOptionsKYCApproved,
        onFilter: customFilterKYCApproved,
      }),
    },
    {
      dataField: "hubspotLifecycleName",
      text: "HUBSPOT",
      sort: true,
      filter: selectFilter({
        options: selectOptionsHubspotLifecycles,
        onFilter: customFilterHubspotLifecycles,
      }),
    },
    {
      dataField: "reason",
      text: "REASON",
      // sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "profile",
      text: "PROFILE",
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestampText",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  });

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setState(prevState => {
        return {
          ...prevState,
          editingUser: true,
          selectedUser: row,
        };
      });
    },
  };

  let data = [];
  if (Object.keys(users).length > 0) {
    for (let i = 0; i < users.length; i++) {
      // Some data formatting before displaying
      let userStatusClass;
      let userStatusIcon;

      let statusText = users[i].status;
      switch (users[i].status) {
        case "new":
          userStatusClass = "statusFlagNewClient";
          userStatusIcon = IconNewClient;
          statusText = "New Client";
          break;
        case "pending":
          userStatusClass = "statusFlagPending";
          userStatusIcon = IconPending;
          break;
        case "rejected":
          userStatusClass = "statusFlagRejected";
          userStatusIcon = IconRejected;
          break;
        default:
          userStatusClass = "statusFlagApproved";
          userStatusIcon = IconApproved;
      }

      users[i].userStatus = (
        <span className={["statusFlag", userStatusClass].join(" ")}>
          <img src={userStatusIcon} alt="" /> {statusText}
        </span>
      );

      users[i].requestStatus = "Pending";

      users[i].timestampText = moment(users[i].timestamp * 1000)
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();

      users[i].agentCodeField =
        users[i].role === "PARTNER" ? (
          <>
            {users[i].agentCode}{" "}
            <FaCrown title="PARTNER" style={{ color: "orange" }} />
          </>
        ) : (
          users[i].agentCode
        );

      users[i].userCountry = countryList[users[i].country];

      users[i].eKYCApprovedIcon =
        users[i].eKYCApproved === true ? (
          <img src={IconApproved} alt="" />
        ) : users[i].eKYCFlagged ? (
          <span className="text-danger">FLAGGED</span>
        ) : (
          <img src={IconRejected} alt="" />
        );
      // Overrides KYC approved icon in case user has uploaded all documents and pending approval
      if (users[i].eKYCPending) {
        users[i].eKYCApprovedIcon = <img src={IconPendingYellow} alt="" />;
      }

      users[i].completedApplication =
        users[i].registered === true ? (
          <img src={IconApproved} alt="" />
        ) : (
          <img src={IconRejected} alt="" />
        );

      users[i].hubspotLifecycleName = users[i].hubspotLifecycleId
        ? HUBSPOT_LIFECYCLES.short[users[i].hubspotLifecycleId]
        : "N/A";

      // users[i].fxlRegistered = state.fxlRequests[users[i].email] ? "Yes" : "No";

      users[i].profile = (
        <Button
          type="button"
          variant="link"
          className="blueButton text-white"
          as={Link}
          to={`/user/profile/${users[i]._id}`}
        >
          PROFILE
        </Button>
      );

      data.push(users[i]);
    }
  }

  const rowStyle = (row, rowIndex) => {
    let style = {};

    if (state.answers[row._id].status === "error") {
      style = { color: "#f00" };
    }

    return style;
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="pt-3 px-3">
            IB Requests{" "}
            {loadingRequests ? <Spinner animation="border" /> : null}
          </h1>
        </Col>
      </Row>

      <Row>
        <Col>
          <BootstrapTable
            bootstrap4
            bordered={false}
            keyField="_id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            hover={true}
            pagination={pagination}
            filter={filterFactory()}
            rowEvents={rowEvents}
            rowStyle={rowStyle}
            headerClasses="tableHeaderClass"
          />

          {selectedUser && state.answers[selectedUser._id] ? (
            <Modal show={state.editingUser} onHide={handleCloseModal} size="lg">
              <Modal.Header className="border-0" closeButton>
                <Modal.Title>IB Application</Modal.Title>
              </Modal.Header>
              <Form>
                <Modal.Body>
                  <p>1. {state.answers[selectedUser._id].question1}</p>
                  <p>
                    Answer:{" "}
                    <strong>{state.answers[selectedUser._id].answer1}</strong>
                  </p>
                  <p>2. {state.answers[selectedUser._id].question2}</p>
                  <p>
                    Answer:{" "}
                    <strong>{state.answers[selectedUser._id].answer2}</strong>
                  </p>
                  <p>3. {state.answers[selectedUser._id].question3}</p>
                  <p>
                    Answer:{" "}
                    <strong>{state.answers[selectedUser._id].answer3}</strong>
                  </p>
                  <p>4. {state.answers[selectedUser._id].question4}</p>
                  <p>
                    Answer:{" "}
                    <strong>{state.answers[selectedUser._id].answer4}</strong>
                  </p>

                  <br />

                  {state.answers[selectedUser._id].status === "error" ? (
                    <p className="text-danger">
                      <strong>Nullpoint error:</strong>{" "}
                      {state.answers[selectedUser._id].errorMessage}
                    </p>
                  ) : null}

                  <Form.Group>
                    <Form.Label column={"lg"}>Comment</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="comment"
                      ref={inputComment}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-between">
                  <Button
                    variant="secondary"
                    className="redButton text-white"
                    onClick={() => handleProcess("rejected")}
                    disabled={disableBtn}
                  >
                    Reject
                  </Button>

                  <Button
                    variant="primary"
                    className="greenButton text-white"
                    style={{ fontWeight: "bold" }}
                    onClick={() => handleProcess("approved")}
                    disabled={disableBtn}
                  >
                    {disableBtn ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Approve
                      </>
                    ) : (
                      "Approve"
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

IbRequests.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(IbRequests);
